@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body{
  background: #f5f6fa;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

*{
  box-sizing: border-box;
}

img{
  max-width: 100%;
  height:  auto;
}