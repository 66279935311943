header.app-header{
  background: #FFF;
  padding: 12px 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 0 10px rgba(28,39,60,.05);
  border-bottom: 1px solid rgba(72,94,144,.16);
  height: 60px;
  z-index: 20;

  .app-logo{
    max-width: 100px;
  }
}