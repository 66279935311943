.side-menu{
  background: #FFF;
  width: 50px;
  padding: 0;
  box-shadow: 0 0 10px rgba(28,39,60,.05);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 11;

  .app-logo{
    padding: 10px;
    border-bottom: 1px solid rgba(255,255,255,.1);
  }

  .side-menu-content{
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background: #000;
    color: #FFF;
  }

  .btn{
    display: block;
    padding: 10px;
    background: #2ca6ff;
    border: 0;
    border-radius: 4px;
    color: #FFF;
    width: 100%;
    cursor: pointer;
    transition: all .4s ease;

    &:hover{
        background-color: #000;
    }
  }


  .crt-items{

    .crt-item{
      text-align: center;
      border-bottom: 1px solid rgba(255,255,255,.1);
      position: relative;

      >a{
        display: flex;
        padding: 15px 10px;
        transition: all .4s ease;
        align-items: center;
        justify-content: center;
        

        >img{
          max-width: 15px;
        }

        &.active,
        &:hover{
          background: #2a2e37;
        }
      }



      .crt-item-sub{
        position: absolute;
        background: #000;
        visibility: hidden;
        opacity: 0;
        top:50%;
        border-radius: 3px;
        left: 60px;
        transform: translateY(-50%) translateX(20px);
        pointer-events: none;
        transition: all 0.3s cubic-bezier(0.5, 0.005, 0.075, 0.985);

        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(0, 0, 0, 0);
          border-right-color: #000;
          border-width: 5px;
          margin-top: -5px;
        }

        ul{
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;

          li{
            list-style: none;
            flex:1;
            width: 40px;

            + li{
              border-left: 1px solid rgba(255,255,255,.1);
            }

            a{
              display: flex;
              padding: 10px;
              align-items: center;
              justify-content: center;

              img{
                transition: all .4s ease;
              }

              &:hover img{
                opacity: .7;
              }
            }

            img{
              max-width: 15px;
            }
          }
        }
      }

      &.show-sub{
        .crt-item-sub{
          transform: translateY(-50%) translateX(0);
          opacity: 1;
          visibility: visible;
          pointer-events: inherit;
        }
      }
    }


    &.bottom{

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .crt-item{
        border-bottom: 0;
        border-top: 1px solid rgba(255,255,255,.1);
      }
    }

  }

  .crt-sub-menu-content{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    right: 0;
  }



  .crt-sub-menu{
    visibility: hidden;
    position: fixed;

    right: 0;
    left:50px;
    top: 0;
    bottom:0;

    color: #FFF;

    border-right: 0;
    opacity: 0;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.5, 0.005, 0.075, 0.985);
    transform: translateX(-220px);


    &.show{
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }

    .crt-sub-menu-title{
      margin: -20px -20px 20px -20px;
      padding: 12px 20px;
      background: #3e434a;
      font-weight: 500;
      color: #FFF;
      height: 42px;
      position: relative;
      z-index: 3;
    }

    .crt-inner-content{
      z-index: 3;
      padding: 20px;
      top: 0;
      bottom: 0;
      width: 220px;
      background: #2a2e37;
      position: absolute;

      .crt-inner-content-wrap{
        top: 42px;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 20px;
        position: absolute;
        overflow-y: scroll;
      }
    }

    ul.pages{

      padding: 0;
      margin: 0 0 20px;
      
      li{
        list-style: none;
        padding: 0;
        margin: 0;
        padding: 7px 13px 7px 0;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        input{
          padding: 0;
          border: 0;
          background: none;
          color: #FFF;
          outline: none;
          
        }

        &.edit{
          padding-right: 0;
          border-bottom: 1px dashed #ededed29;
        }

        a{
          color: #FFF;
          transition: all .4s ease;

          &:hover{
            color: #3dbfe8;
          }
        }
        
        button{
          background: none;
          border: 0;
          padding: 0;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          transition: all .4s ease;

          &.act2{
            right: 20px;
          }

          &.close{
            padding: 1px;
          }

        }

        &.active{
          a{
            color: #3dbfe8;
          }
        }

        &:hover{
          button{
            opacity: 1;
            pointer-events: inherit;
            visibility: visible;
          } 
        }
      }
    }

    .crt-menu-overlay{
      z-index: 1;
      background: rgba(0,0,0,0);
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
    }



   

    .crt-field-group{
      margin-bottom: 10px;

      .lbl{
        font-size: 13px;
        margin-bottom: 3px;
        color: #828b92;
      }

      input{
        border-radius: 3px;
        border: 0;
        padding: 10px;
        width: 100%;
        box-shadow: none!important;
        outline: none!important;
        background: #3e434b;
        color: #FFF;
      }
    }



    .crt-inner-content{
      
      .crt-field-collapse{
        border-top: 1px solid #1e222b;
        
        +.crt-field-collapse{
          border-top: 1px solid #1e222b;
        }

        &:last-of-type{
          border-bottom: 1px solid #1e222b;
        }
      }  
    }

    .crt-field-collapse{
      position: relative;
      margin:0 -20px;
      padding: 0 20px;
      
      
      .collapse-toggle{
        width: 10px;
        height: 10px;
        position: absolute;
        right: 10px;
        top: 15px;
        line-height: 20px;

        img{
          height: 10px;
          width: 10px;
          display: block;
          opacity: .4;
        }
      }

      .crt-field-collapse-title{
        padding: 10px 20px;
        margin: 0 -20px;
        cursor: pointer;
        position: relative;
      }

      .crt-field-collapse-content{
        display: none;
        padding-bottom: 20px;
      }

      &.open{
        .collapse-toggle{
          transform: rotateX(-180deg);
        }
      }

    }




  }


  .blocks-menu{

    ul.menu-items{
      margin: 0;
      padding: 0;

      >li{
        list-style: none;
        transition: all .4s ease;

        >span{
          display: block;
          padding:7px 0;
          cursor: pointer;
        }

        .blocks-sub-menu{
          display: none;
          position: fixed;
          left: 220px;
          right: 0;
          width: 350px;
          top: 0;
          bottom: 0;
          color: #333;
          padding: 10px;
          padding-right: 40px;

          &::-webkit-scrollbar {
            width: 0px;
            scrollbar-width: 0;
          }

          
          overflow-y: auto;
          
          
          //box-shadow: 0 20px 20px 0 rgba(0,0,0,.2);


          ul{
            padding: 0;
            margin: 0;

            li{
              list-style: none;
              margin-bottom: 10px;
              border-radius: 6px;
              overflow: hidden;
              box-shadow: 0 20px 20px 0 rgba(0,0,0,.2);
              transition: all .2s ease;
              transform: translateZ(0);

              img{
                display: block;
              }

              &:hover{
                transform:translateZ(0) scale(1.02);
              }
            }
          }

        }


        &:hover{
          color: #3dbfe8;
        }

        &.active{
          color: #3dbfe8;

          .blocks-sub-menu{
            display: block;
          }
        }

      }
    }

  }


  .reorder-blocks-menu{

    ul{

      margin: 0;
      padding: 0;

      li{
        list-style: none;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 10px;
      }
    }
  }

  .page-color-swatch-wrap{

    display: inline-block;
    margin-right: 3px;

    .page-color-swatch{

      display: inline-block;
      cursor: pointer;

      div{
        box-shadow: 0 0 0 1px rgba(0,0,0,.1);
        border-radius: 50%;
        width: 23px;
        height: 23px;
        border: 2px solid #FFF;
      }
    }


    .color-picker-popover{
      position: absolute;
      z-index: 2;
    }

    .color-picker-cover{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }


  }

  .ui.selection.dropdown{
    border: 0;
    background: #3e434b;
        color: #FFF;
  }


  .ui.selection.active.dropdown .menu{
    border-color: #FFF;
    margin: 0;
    min-width: 100%;
    width: 100%;
  }

}


.reorder-container{
  //transform: translate(0);
  position: relative;

  div{
    margin-bottom:5px;
    border-radius: 5px;
    overflow: hidden;

    img{
      display: block;
    }

    &.item-dragging{
      left: 30px!important;
    }
  }
}





.color-picker-popover{
  position: relative;

  .close-color-picker{
      position: absolute;
      background: #555;
      box-shadow: 0 0 0 3px #FFF;
      border-radius: 50%;
      right:-15px;
      top: -5px;
      width: 20px;
      height: 20px;
      z-index: 22;
      padding: 0 7px;
      cursor: pointer;

      svg{
          width: 100%;
          height: 100%;

          *{
              fill: #FFF
          }
      }
  }

  .sketch-picker {
      position: absolute!important;
      z-index: 2!important;
      right: -10px!important;
      left: -10px!important;
      width: auto!important;
      margin-top: 5px;
  }
}




.color-picker-field-wrap{

  padding-left: 40px;
  position: relative;

  .color-picker-value{
      position: absolute;
      left: 0;
      top: 1px;
      padding: 10px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      border: 3px solid #FFF;
      cursor: pointer;

  }

  input{
      text-transform: uppercase;
  }
}


.settings-color-picker{

  .color-picker-popover{
    display: none;
  }
}