.d6f-block-editor{
    position: fixed;
    left: 50px;
    background: #2a2e37;
    width: 220px;
    top: 0;
    bottom: 0;
    color: #FFF;
    font-size: 13px;
    pointer-events: none;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s cubic-bezier(0.5, 0.005, 0.075, 0.985);
    transform: translateX(-220px);
    

    &.show{
        visibility: visible;
        pointer-events: inherit;
        opacity: 1;
        z-index: 10;
        transform: translateX(0);
    }


    .close-editor-panel{

        position: absolute;
        width: 24px;
        padding: 2px 0;
        height: 24px;
        top: 11px;
        right: 7px;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;


        svg{
            width: 9px;
            height: auto;

            text-align: center;
            * {
                fill: #FFF;
            }
        }

        &:hover{
            background-color: #000;
        }
    }

    .d6f-block-editor-title{
        margin: 0;
        padding: 12px 20px;
        background: #3e434a;
        font-weight: 500;
        font-size: 14px;
        height: 45px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span.back{
            display: inline-block;
            cursor: pointer;
            margin-right: 10px;

            svg{
                width: 16px;
                height: auto;
                *{
                    fill: #FFF;
                }
            }
        }
        
    }

    .d6f-editor-panel-content{
        position: absolute;
        left: 0;
        right: 0;
        top: 45px;
        bottom: 0;
        padding-bottom: 10px;
        overflow: auto;

        &.generating-content{

            .block-fields{
                position: relative;
                opacity: .4;

                &::after{
                    opacity: .6;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: '';
                    z-index: 3;
                }
            }
        }
    }

    .generate-content-wrap{
        margin-top: 10px;

        .generate-content-btn{
            display: block;
            padding: 10px;
            background: #2ca6ff;
            border: 0;
            border-radius: 4px;
            color: #FFF;
            width: 100%;
            cursor: pointer;
            transition: all .4s ease;

            &:hover{
                background-color: #000;
            }
        }
    }

   

    ul{
        margin: 0;
        padding: 0;
        
        li{
            list-style: none;
        }

        &.element-list{

            li{
                padding: 10px 20px;
                
                cursor: pointer;
    
                + li{
                    border-top: 1px solid #20242d;
                }
    
                &:hover{
                    background: #000;
                }
            }
        }

    }

    .element-field-group{
        padding: 5px 20px;
    }

    .element-label{
        font-size: 13px;
        margin-bottom: 3px;
        color: #828b92;
    }

    

   

    
    .element-img{
        background: rgba($color: #FFFFFF, $alpha: .1);
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 10px;
        position: relative;

        img{
            display: block;
        }

        .uploading{
            width: 20px;
            margin: 0 auto 10px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -10px;
            margin-top: -10px;
            text-align: center;
            z-index: 4;
    
            .spinner-border{
                width: 20px;
                height: 20px;
            }
        }

    }

    .element-upload-img{
        background: #3e434b;
        padding: 10px;
        cursor: pointer;
        text-align: center;
        color: #FFF;
        border-radius: 3px;
        margin-bottom: 10px;
        outline: none!important;
        border: 0!important;
        box-shadow: none!important;
    }

    textarea, input{
        border: 0;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        outline: none!important;
        height: auto;
        background: #3e434b;
        color: #FFF;
        resize: none;
    }

    textarea{
        min-height: 150px;
    }
}


