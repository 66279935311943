.preview-panel{
  padding-top: 20px;
  padding-left: 70px;
  padding-right: 20px;
  padding-bottom: 0;
  transition: all .3s ease;

  &.with-block-editor{
    padding-left: 290px;
  }

  overflow: hidden;

  //border-radius: 4px;
  //display: flex;
  //width: 1440px;
  //transform: scale(.7);
  //transform-origin: 50% 0;

  iframe,
  iframe#d6f-iframe{
    //width: 1440px;
    width: 100%;
    min-height: calc(100vh - 45px);
    box-shadow: 0 0 10px rgba(28,39,60,.05);
    border: 1px solid rgba(72,94,144,.16);
    display: block;

    &.mobile{
      width: 414px;
      margin:0 auto;
      min-height: 0;
      max-height: 736px;
    }

    &.tablet{
      margin:0 auto;
      width: 768px;
      min-height: 0;
      max-height: 734px;
    }
  }
}

.loading{

  position: fixed;
  left:50%;
  top:50%;
  width: 30px;
  height: 30px;
  z-index: 22;
  background: #FFF;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.15);
  line-height: 28px;
  margin-left:25px;
  transform: translate(-50%, -50%);

  .spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: 0.1em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
  }

}


.crt-select{
  position: relative;

  .selected{
    border: 1px solid rgba(255,255,255,.1);
    border-radius: 3px;
    padding: 5px 10px;
    position: relative;
    background: #FFF;
    color: #333;
    
    &:after{
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      background-image: url("../img/caret-down-black.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      top: 13px;
      opacity: .5;
      right: 10px;
    }
  }



  .select-list{
    position: absolute;
    background: #000;
    border-radius: 3px;
    left: 0;
    right: 0;
    top:100%;
    display: none;

    ul{

      margin: 0;
      padding: 0;

      li{
        list-style: none;
        padding: 3px 10px;

        + li{
          border-top: 1px solid rgba(255,255,255,.1);
        }
      }
    }
  }

  &.open{
    .select-list{
      display: block;
    }
  }

}

.add-blocks-btn{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 150px;
  height: 150px;
  display: flex;
  background: #FFF;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: all .4s ease;
  box-shadow:0 0 30px 0 rgba(0,0,0,.1);
  cursor: pointer;
  margin-left: 25px;
  flex-wrap: wrap;
  align-content: center;

  &:hover{
    box-shadow:0 0 30px 0 rgba(0,0,0,.2);
    background: #FFF;
  }

  div{
    width: 100%;
  }
  
  span{
    display: block;
    color: #666;
    width: 100%;

    img{
      max-height: 20px;
      margin-bottom: 10px;
    }
  }
}